import React from "react";
import neometals from "./neometals";
import sms from "./sms";
import hansard from "./hansard";
import copia from "./copia";
import mcbride from "./mcbride";
import mpevans from "./mpevans";
import rit from "./rit";
import d36f45d94876db3ff1e37684d7016f1b from "./d36f45d94876db3ff1e37684d7016f1b";
import speedyhire from "./speedyhire";
import rvrg from "./rvrg";
import rvrb from "./rvrb";

const widgets = {
    "neometals": neometals,
    "sms": sms,
    "hansard": hansard,
    "copia": copia,
    "mcbride": mcbride,
    "mpevans": mpevans,
    "rit": rit,
    "d36f45d94876db3ff1e37684d7016f1b": d36f45d94876db3ff1e37684d7016f1b,
    "speedy-hire": speedyhire,
    "rvrg": rvrg,
    "rvrb": rvrb,
}

export default widgets;